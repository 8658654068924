import type { IYaSlotConfig } from "@magnit/core/src/containers/VYaSlot/VYaSlot.types";

export enum CatalogType {
  Promo = 1,
  Goods = 2,
}

export type ICategoryPreviewsResponse = Array<{
  name: string;
  categoryId?: number;
  categoryType: CatalogType;
  publicImageURL: string;
}>;

export interface ICategoryPreview {
  name: string;
  url: string;
  publicImageURL: string;
}

export interface IMainSlide {
  name: string;
  payload: IYaSlotConfig;
}

export interface IEverGreenBanner {
  id: number;
  internalLink: string;
  publicImageURL: string;
  title: string;
}

export interface ITicker {
  id: number;
  offerURL: string;
  publicImageURL: string;
  disclaimer?: string;
  title?: string;
}

export interface ISpecialOffer {
  adfoxPayload: IYaSlotConfig | IYaSlotConfig[]; // Данные adfox
  id: number;
  position: number; // Позиция слайда в слайдере
  title: string;
}
export interface IMagazine {
  title: string;
  description: string;
  publicImageURL: string;
  label: string;
  url: string;
}
export interface IOwnBrand {
  article: {
    code: string;
    id: number;
  };
  disclaimer: string;
  id: number;
  name: string;
  position: number;
  publicImageURL: string;
}

export enum IRaffleTitleType {
  black = 1,
  white = 2,
}

export interface IRaffle {
  disclaimer: string;
  id: number;
  imageUrl: string;
  isActive: boolean;
  position: number;
  promoDate: {
    end: string;
    start: string;
  };
  // Связанная сущность Розыгрыш
  raffle: {
    code: string; // Транслитерация в латиницу поля title сущности Розыгрыш
    id: number;
  };
  title: string;
  titleType: IRaffleTitleType;
}

export interface ICategorySectionResponse {
  adfoxPayload?: IYaSlotConfig;
  categoryId?: number;
  categoryType: CatalogType;
  title: string;
}
